<template>
  <div class="audience-section">
    <div class="d-flex justify-center">
      <v-btn-toggle
        v-if="items.length > 1"
        v-model="selectedIndex"
        active-class="primary white--text"
      >
        <v-btn
          v-for="item in items"
          :key="item"
        >
          {{ item === 'likes' ? 'Engagers' : item }}
        </v-btn>
      </v-btn-toggle>
    </div>

    <div :key="selectedIndex">
      <!-- this should only be visible for followers -->
      <template v-if="audienceData.extraData">
        <credibility
          :platform="platform"
          :selected-tab="selectedItem"
          :audience-data="audienceData"
          class="mt-3"
        />
      </template>

      <!-- If we have the data to show the histogram -->
      <template v-if="selectedAudienceData.credibilityGraph.length">
        <credibility-graph
          :user-data="userData"
          :audience-data="selectedAudienceData"
          class="mt-6"
        />
      </template>

      <v-row class="mt-4">
        <v-col
          v-if="selectedAudienceData.audienceTypes && selectedAudienceData.audienceTypes.length"
          cols="12"
          lg="6"
        >
          <audience-type
            :platform="platform"
            :audience-data="selectedAudienceData"
            :selected-tab="selectedItem"
            :all-tabs="items"
            :total-values="totalValues"
            :all-data="audienceData"
          />
        </v-col>

        <v-col
          v-if="selectedAudienceData.country && selectedAudienceData.country.length"
          cols="12"
          lg="6"
        >
          <location
            :platform="platform"
            :audience-data="selectedAudienceData"
            :selected-tab="selectedItem"
            :all-tabs="items"
            :total-values="totalValues"
            :all-data="audienceData"
          />
        </v-col>

        <v-col
          v-if="selectedAudienceData.cityGeo && selectedAudienceData.cityGeo.length"
          cols="12"
          lg="6"
        >
          <location-map
            :platform="platform"
            :audience-data="selectedAudienceData"
            :selected-tab="selectedItem"
            :all-tabs="items"
            :total-values="totalValues"
            :all-data="audienceData"
          />
        </v-col>

        <v-col
          v-if="selectedAudienceData.age && selectedAudienceData.gender"
          cols="12"
          lg="6"
        >
          <age-and-gender-tabs
            :platform="platform"
            :audience-data="selectedAudienceData"
            :selected-tab="selectedItem"
            :all-tabs="items"
            :total-values="totalValues"
            :all-data="audienceData"
          />
        </v-col>

        <!--
        <v-col
          v-if="selectedAudienceData.language"
          cols="12"
          lg="6"
        >
          <ethnicity-and-language
            :platform="platform"
            :audience-data="selectedAudienceData"
            :selected-tab="selectedItem"
            :all-tabs="items"
            :total-values="totalValues"
            :all-data="audienceData"
          />
        </v-col>
        -->

        <v-col
          v-if="selectedAudienceData.reachability && selectedAudienceData.reachability.length"
          cols="12"
          lg="6"
        >
          <reachability
            :platform="platform"
            :selected-tab="selectedItem"
            :total-value="totalValues[selectedItem]"
            :audience-data="selectedAudienceData"
          />
        </v-col>

        <v-col
          v-if="selectedAudienceData.notable"
          cols="12"
          lg="6"
        >
          <notable-users
            :platform="platform"
            :selected-tab="selectedItem"
            :total-value="totalValues[selectedItem]"
            :audience-data="selectedAudienceData"
          />
        </v-col>

        <v-col
          v-if="selectedAudienceData.audienceBrandAffinity"
          cols="12"
          lg="6"
        >
          <brand-affinity
            :platform="platform"
            :audience-data="selectedAudienceData"
            :selected-tab="selectedItem"
            :all-tabs="items"
            :total-values="totalValues"
            :all-data="audienceData"
          />
        </v-col>

        <v-col
          v-if="selectedAudienceData.interests && selectedAudienceData.interests.length"
          cols="12"
          lg="6"
        >
          <interests
            :platform="platform"
            :audience-data="selectedAudienceData"
            :selected-tab="selectedItem"
            :all-tabs="items"
            :total-values="totalValues"
            :all-data="audienceData"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// Import child components
const Location = () => import(/* webpackChunkName: "blocks-audience-location" */ "@/blocks/user-profile/audience/Location.vue")
const Interests = () => import(/* webpackChunkName: "blocks-audience-interests" */ "@/blocks/user-profile/audience/Interests.vue")
const Credibility = () => import(/* webpackChunkName: "blocks-audience-credibility" */ "@/blocks/user-profile/audience/Credibility.vue")
const LocationMap = () => import(/* webpackChunkName: "blocks-audience-location-map" */ "@/blocks/user-profile/audience/LocationMap.vue")
const Reachability = () => import(/* webpackChunkName: "blocks-audience-reachability" */ "@/blocks/user-profile/audience/Reachability.vue")
const NotableUsers = () => import(/* webpackChunkName: "blocks-audience-notable-users" */ "@/blocks/user-profile/audience/NotableUsers.vue")
const AudienceType = () => import(/* webpackChunkName: "blocks-audience-type" */ "@/blocks/user-profile/audience/AudienceType.vue")
const BrandAffinity = () => import(/* webpackChunkName: "blocks-audience-brand-affinity" */ "@/blocks/user-profile/audience/BrandAffinity.vue")
const AgeAndGenderTabs = () => import(/* webpackChunkName: "blocks-audience-age-gender" */ "@/blocks/user-profile/audience/AgeAndGender.vue")
const CredibilityGraph = () => import(/* webpackChunkName: "blocks-audience-credibility-graph" */ "@/blocks/user-profile/audience/CredibilityGraph.vue")
// const EthnicityAndLanguage = () => import(/* webpackChunkName: "blocks-audience-ethnicity-language" */ "@/blocks/user-profile/audience/EthnicityAndLanguage.vue")

// Export the SFC
export default {
  // Name of the component
  name: "Audience",

  // Accept incoming data from parent
  props: {
    platform: {
      type: String,
      required: true,
    },

    userData: {
      type: Object,
      required: true
    },

    audienceData: {
      type: Object,
      required: true
    },

    totalValues: {
      type: Object,
      required: true
    }
  },

  // Register the components
  components: {
    Location,
    Interests,
    Credibility,
    LocationMap,
    Reachability,
    NotableUsers,
    BrandAffinity,
    AudienceType,
    AgeAndGenderTabs,
    CredibilityGraph,
    // EthnicityAndLanguage,
  },

  // Define local data variables
  data: () => ({
    selectedIndex: 0,
  }),

  // Define readonly computable data variable
  computed: {
    /**
     * Get the possible tab options for the profile's platform
     *
     * @returns {Array}
     */
    items() {
      const map = {
        instagram: [{
          text: "followers",
          isAvailable: this.audienceData.extraData.isFollowersDataAvailable
        },
        {
          text: "likes",
          isAvailable: this.audienceData.extraData.isLikersDataAvailable
        }],

        youtube: [{
          text: "followers",
          isAvailable: this.audienceData.extraData.isFollowersDataAvailable
        },
        {
          text: "comments",
          isAvailable: this.audienceData.extraData.isCommentersDataAvailable
        }],

        tiktok: [{
          text: "followers",
          isAvailable: this.audienceData.extraData.isFollowersDataAvailable
        },
        {
          text: "likes",
          isAvailable: this.audienceData.extraData.isLikersDataAvailable
        }]
      }

      return map[this.platform].filter((item) => item.isAvailable).map((item) => item.text)
    },

    /**
     * Get the selected tab name
     *
     * @returns {String}
     */
    selectedItem() {
      return this.items[this.selectedIndex]
    },

    /**
     * Get the audience data for the selected tab
     *
     * @returns {Object}
     */
    selectedAudienceData() {
      return this.audienceData[this.selectedItem]
    },
  }
}
</script>
